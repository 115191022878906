.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.privacy-policy-content {
  overflow-y: scroll;
  height: 500px; /* Adjust the height as needed */
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 10px;
}

.privacy-policy-content::-webkit-scrollbar {
  width: 6px;
}

.privacy-policy-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.privacy-policy-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
  content: " ";
}
.lst-kix_list_3-2 > li:before {
  content: " ";
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_8-1 > li:before {
  content: " ";
}
.lst-kix_list_8-2 > li:before {
  content: " ";
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: " ";
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: " ";
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: " ";
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: " ";
}
.lst-kix_list_3-8 > li:before {
  content: " ";
}
.lst-kix_list_8-5 > li:before {
  content: " ";
}
.lst-kix_list_8-6 > li:before {
  content: " ";
}
.lst-kix_list_8-3 > li:before {
  content: " ";
}
.lst-kix_list_3-6 > li:before {
  content: " ";
}
.lst-kix_list_3-7 > li:before {
  content: " ";
}
.lst-kix_list_8-4 > li:before {
  content: " ";
}
.lst-kix_list_8-8 > li:before {
  content: " ";
}
.lst-kix_list_5-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_4-8 > li:before {
  content: " ";
}
.lst-kix_list_5-3 > li:before {
  content: " ";
}
.lst-kix_list_4-7 > li:before {
  content: " ";
}
.lst-kix_list_5-2 > li:before {
  content: " ";
}
.lst-kix_list_5-1 > li:before {
  content: " ";
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li:before {
  content: " ";
}
ul.lst-kix_list_8-4 {
  list-style-type: none;
}
ul.lst-kix_list_8-5 {
  list-style-type: none;
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
  content: " ";
}
.lst-kix_list_5-8 > li:before {
  content: " ";
}
ul.lst-kix_list_8-2 {
  list-style-type: none;
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_8-3 {
  list-style-type: none;
}
ul.lst-kix_list_8-8 {
  list-style-type: none;
}
ul.lst-kix_list_8-6 {
  list-style-type: none;
}
ul.lst-kix_list_8-7 {
  list-style-type: none;
}
ul.lst-kix_list_4-0 {
  list-style-type: none;
}
ul.lst-kix_list_4-1 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: " ";
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: " ";
}
ul.lst-kix_list_8-0 {
  list-style-type: none;
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_8-1 {
  list-style-type: none;
}
ul.lst-kix_list_4-2 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
.lst-kix_list_6-1 > li:before {
  content: " ";
}
.lst-kix_list_6-3 > li:before {
  content: " ";
}
.lst-kix_list_6-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_6-4 > li:before {
  content: " ";
}
.lst-kix_list_6-2 > li:before {
  content: " ";
}
.lst-kix_list_6-8 > li:before {
  content: " ";
}
.lst-kix_list_6-5 > li:before {
  content: " ";
}
.lst-kix_list_6-7 > li:before {
  content: " ";
}
.lst-kix_list_7-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_6-6 > li:before {
  content: " ";
}
.lst-kix_list_2-6 > li:before {
  content: " ";
}
.lst-kix_list_2-7 > li:before {
  content: " ";
}
.lst-kix_list_7-4 > li:before {
  content: " ";
}
.lst-kix_list_7-6 > li:before {
  content: " ";
}
.lst-kix_list_2-4 > li:before {
  content: " ";
}
.lst-kix_list_2-5 > li:before {
  content: " ";
}
.lst-kix_list_2-8 > li:before {
  content: " ";
}
.lst-kix_list_7-1 > li:before {
  content: " ";
}
.lst-kix_list_7-5 > li:before {
  content: " ";
}
.lst-kix_list_7-2 > li:before {
  content: " ";
}
.lst-kix_list_7-3 > li:before {
  content: " ";
}
ul.lst-kix_list_7-5 {
  list-style-type: none;
}
ul.lst-kix_list_7-6 {
  list-style-type: none;
}
ul.lst-kix_list_7-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_7-4 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
ul.lst-kix_list_7-7 {
  list-style-type: none;
}
ul.lst-kix_list_7-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
  content: " ";
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_7-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_7-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: " ";
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_4-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_4-1 > li:before {
  content: " ";
}
.lst-kix_list_4-4 > li:before {
  content: " ";
}
.lst-kix_list_4-3 > li:before {
  content: " ";
}
.lst-kix_list_4-5 > li:before {
  content: " ";
}
.lst-kix_list_4-2 > li:before {
  content: " ";
}
.lst-kix_list_4-6 > li:before {
  content: " ";
}
ul.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ul.lst-kix_list_6-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-8 {
  list-style-type: none;
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_6-2 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-3 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: " ";
}
.lst-kix_list_1-2 > li:before {
  content: " ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_6-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: " ";
}
.lst-kix_list_1-4 > li:before {
  content: " ";
}
.lst-kix_list_1-7 > li:before {
  content: " ";
}
.lst-kix_list_1-5 > li:before {
  content: " ";
}
.lst-kix_list_1-6 > li:before {
  content: " ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_2-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_2-1 > li:before {
  content: " ";
}
.lst-kix_list_1-8 > li:before {
  content: " ";
}
.lst-kix_list_2-2 > li:before {
  content: " ";
}
.lst-kix_list_2-3 > li:before {
  content: " ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c10 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.45;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7.5pt;
}
.c2 {
  margin-left: 54pt;
  padding-top: 7.5pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.45;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7.5pt;
}
.c20 {
  margin-left: 54pt;
  padding-top: 7.5pt;
  padding-bottom: 0pt;
  line-height: 1.45;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7.5pt;
}
.c5 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 10.5pt;
  font-family: "SUKHUMVITSET-TEXT";
}
.c16 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.45;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7.5pt;
}
.c13 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 22pt;
  font-family: "Sukhumvit Set";
  font-style: normal;
}
.c11 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 6.5pt;
  font-family: "SUKHUMVITSET-TEXT";
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "SUKHUMVITSET-TEXT";
  font-style: normal;
}
.c14 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "SUKHUMVITSET-TEXT";
  font-style: normal;
}
.c26 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c8 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0999999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c7 {
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "SUKHUMVITSET-TEXT";
  color: #000000;
  font-weight: 700;
}
.c9 {
  color: #400099;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c18 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
.c22 {
  font-weight: 400;
  font-size: 10pt;
  font-family: "SUKHUMVITSET-TEXT";
}
.c6 {
  font-size: 9pt;
  font-family: "SUKHUMVITSET-TEXT";
  font-weight: 400;
}
.c24 {
  font-weight: 400;
  font-size: 18pt;
  font-family: "SUKHUMVITSET-TEXT";
}
.c19 {
  font-size: 10.5pt;
  font-family: "SUKHUMVITSET-TEXT";
  font-weight: 700;
}
.c23 {
  font-size: 10.5pt;
  font-family: "SUKHUMVITSET-TEXT";
  font-weight: 400;
}
.c25 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c28 {
  font-size: 22pt;
  font-family: "Sukhumvit Set";
  font-weight: 700;
}
.c17 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c15 {
  vertical-align: baseline;
  color: #000000;
}
.c27 {
  margin-left: 56.7pt;
  padding-left: 0pt;
}
.c12 {
  padding: 0;
  margin: 0;
}
.c21 {
  text-decoration: none;
  font-style: normal;
}
.c3 {
  height: 10pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 10pt;
  font-family: "Times New Roman";
}
p {
  margin: 0;
  color: #000000;
  font-size: 10pt;
  font-family: "Times New Roman";
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
