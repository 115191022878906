.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 22px;
  object-fit: cover;
  margin-right: 14px;
}
.home-links {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text01 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link1 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link2 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link3 {
  text-decoration: none;
}
.home-burger-menu {
  fill: #105749;
  width: 24px;
  height: 24px;
}
.home-max-width1 {
  align-items: center;
}
.home-content {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-subtitle {
  color: var(--dl-color-scheme-orange100);
  margin-bottom: var(--dl-space-space-unit);
}
.home-title {
  color: var(--dl-color-scheme-white);
  font-size: 34px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text03 {
  color: var(--dl-color-scheme-lightgreen);
}
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image01 {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}
.home-hero-image {
  flex: 1;
  width: 550px;
  height: 646px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/hero-600w.png");
  background-position: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.home-image02 {
  width: 469px;
  height: 400px;
  object-fit: cover;
}
.home-image03 {
  width: 484px;
  height: 210px;
  object-fit: cover;
}
.home-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.home-image04 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
.home-section {
  background-color: rgba(255, 199, 139, 0.14);
}
.home-max-width2 {
  align-items: stretch;
}
.home-content1 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text08 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text09 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text10 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.home-text12 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-link4 {
  text-decoration: none;
}
.home-image06 {
  width: 575px;
  height: 493px;
  object-fit: cover;
}
.home-max-width3 {
  align-items: stretch;
}
.home-image08 {
  width: 499px;
  height: 584px;
  object-fit: cover;
}
.home-content2 {
  width: 50%;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text13 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text14 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text15 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-link5 {
  text-decoration: none;
}
.home-section2 {
  background-color: rgba(255, 199, 139, 0.14);
}
.home-max-width4 {
  align-items: stretch;
}
.home-content3 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text16 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text17 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text18 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.home-text20 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-link6 {
  text-decoration: none;
}
.home-image10 {
  width: 503px;
  height: 427px;
  object-fit: cover;
}
.home-section3 {
  background-color: rgba(255, 199, 139, 0.14);
}
.home-max-width5 {
  align-items: stretch;
}
.home-content4 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text25 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text26 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.home-link7 {
  display: contents;
}
.home-icon2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.home-text28 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text29 {
  font-size: 25px;
}
.home-text30 {
  font-size: 25px;
}
.home-text31 {
  font-size: 25px;
}
.home-text32 {
  font-size: 25px;
}
.home-text33 {
  font-size: 25px;
}
.home-text34 {
  font-size: 25px;
}
.home-text35 {
  font-size: 25px;
}
.home-text36 {
  font-size: 25px;
}
.home-image11 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
@media(max-width: 991px) {
  .home-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image01 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image {
    margin-bottom: 0px;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-content1 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image05 {
    padding-bottom: 0px;
  }
  .home-max-width3 {
    flex-direction: column;
  }
  .home-image07 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content2 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-content3 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image09 {
    padding-bottom: 0px;
  }
  .home-max-width5 {
    flex-direction: column;
  }
  .home-content4 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image11 {
    padding-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-links {
    display: none;
  }
  .home-icon {
    fill: var(--dl-color-scheme-green100);
  }
  .home-max-width1 {
    flex-direction: column-reverse;
  }
  .home-content {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image {
    margin-right: 0px;
  }
  .home-content1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content4 {
    width: 100%;
    margin-right: 0px;
  }
}

.link-button {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
  cursor: pointer;
}

.link-button:hover {
  color: #00b336;
}

/* Add your other styles above this line */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
  }

  .home-links {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: #fff;
    padding: 1rem;
  }

  .home-links.active {
    display: flex;
  }

  .home-logo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .home-burger-menu {
    display: block;
  }
}

